import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home.vue"),
    meta: { title: "首页" },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/product.vue"),
    meta: { title: "选品" },
  },
  {
    path: "/move",
    name: "move",
    component: () => import("../views/move.vue"),
    meta: { title: "移动端首页" },
  },
  {
    path: "/download",
    name: "download",
    component: () => import("../views/download/index.vue"),
    meta: { title: "app下载页" },
  },
  {
    path: "/wholconsi",
    name: "wholconsi",
    component: () => import("../views/flow/wholconsi.vue"),
    meta: { title: "批发寄卖流程" },
  },
  {
    path: "/check",
    name: "check",
    component: () => import("../views/agreements/check.vue"),
    meta: { title: "商家入驻" },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/agreements/privacy.vue"),
    meta: { title: "隐私政策" },
  },
  {
    path: "/setup",
    name: "setup",
    component: () => import("../views/agreements/setup.vue"),
    meta: { title: "开店协议" },
  },
  {
    path: "/uservice",
    name: "uservice",
    component: () => import("../views/agreements/uservice.vue"),
    meta: { title: "用户服务" },
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("../views/agreements/withdraw.vue"),
    meta: { title: "app提现规则" },
  },
  {
    path: "/exposure",
    name: "exposure",
    component: () => import("../views/videos/exposure.vue"),
    meta: { title: "曝光视频" },
  },
  {
    path: "/assist",
    name: "assist",
    component: () => import("../views/flow/assist.vue"),
    meta: { title: "切片帮助中心" },
  },
  {
    path: "/process",
    name: "process",
    component: () => import("../views/videos/process.vue"),
    meta: { title: "高佣选品" },
  },
  {
    path: "/bucuinse",
    name: "bucuinse",
    component: () => import("../views/videos/bucuinse.vue"),
    meta: { title: "首单购买教程" },
  },
  {
    path: "/liberal",
    name: "liberal",
    component: () => import("../views/agreements/liberal.vue"),
    meta: { title: "淘铺开通规则" },
  },
  {
    path: "/subsidy",
    name: "subsidy",
    component: () => import("../views/exposure/subsidy.vue"),
    meta: { title: "曝光补贴" },
  },
  {
    path: "/school",
    name: "school",
    component: () => import("../views/videos/school/index.vue"),
    meta: { title: "商学院" },
  },
  {
    path: "/tutorial",
    name: "tutorial",
    component: () => import("../views/videos/school/tutorial.vue"),
    meta: { title: "商学院视频教程" },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
