<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      // ruinse: ''
    }
  },
  // watch: {
  //   $route(val) {
  //     //val即是this.$route
  //     //根据路由控制其他参数做不同处理
  //     // console.log(val.path);
  //   },
  // },
  created() {
    // this.thuione();
  },
  mounted() { },
  // methods: {
  //   // 判断是否是手机端，如果是，返回true
  //   _isMobile() {
  //     let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  //     return flag
  //   },
  
  //   thuione() {
  //     // 根据不同路由跳转不同页面
  //     if (this._isMobile()) {
  //       // 判断路由地址是/move就去移动端官网
  //       // this.$router.replace('/download')
  //       console.log('手机端');
  //       console.log(this.$route);
  //     } else {
  //       console.log('pc端');
  //       console.log(this.$route);
  //       this.$router.push({name:'home'});
  //     }
  //   }
  // }

}
</script>

<style></style>
